<template>
  <div class="top-nav">
    <div class="brand">
      <!-- <h4 class="">Shock Shield.io</h4> -->
      <div class="brand">
        <img
          class="navlogo"
          src="../../assets/images/ShockShield.png"
          alt="ShockShield"
        />
      </div>
    </div>
    <div class="nav-content">
      <ul>
        
        <li class="nav-item">
          <div
            class="nav-link red-circle"
            @click="toggleNotifications"
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            <img src="../../assets/images/shock-sjield/notification.png" alt="bell" />
            <span class="button__badge" v-if="notificationCount">{{
              notificationCount
            }}</span>
          </div>
          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Notifications
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body over">
                  <p><strong>Labels</strong></p>
                  <div class="row">
                    <div class="col-4 my-1">
                      <div class="labels type1">
                        <span>Order Creation</span>
                      </div>
                    </div>
                    <div class="col-4 my-1">
                      <div class="labels type2">
                        <span>Shipping address fraud</span>
                      </div>
                    </div>
                    <div class="col-4 my-1">
                      <div class="labels type4">
                        <span>Refund Order</span>
                      </div>
                    </div>

                    <div class="col-4 my-1">
                      <div class="labels type3">
                        <span>Customer Creation</span>
                      </div>
                    </div>
                    
                    <div class="col-4 my-1">
                      <div class="labels type5">
                        <span>Unconfirmed Order</span>
                      </div>
                    </div>

                    <div class="col-4 my-1">
                      <div class="labels type6">
                        <span>Partial Refund</span>
                      </div>
                    </div>

                  </div>
                  <p class="my-1"><strong>Orders & Refunds</strong></p>

                  <ul v-if="showLoader == 1" class="notifications-list justify-content-center">
                    <div  class="lds-dual-ring-notification" id="loading"></div>
                  </ul>
                  <ul v-if="showLoader != 1" class="notifications-list flex-column align-items-start">
                      <div
                        v-for="notification in notifications"
                        :key="notification.NotificationID"
                        class="w-100"
                      >
                        <li
                          v-if="notification?.Message"
                          class="p-3"
                          :class="{
                            'type1': notification.notification_type === 1,
                            'type2': notification.notification_type === 2,
                            'type3': notification.notification_type === 3,
                            'type4': notification.notification_type === 4,
                            'type5': notification.notification_type === 5,
                            'type6': notification.notification_type === 6,
                            'type7': notification.notification_type === null,
                          }"
                        >
                          <!-- Display your notification content here -->
                          {{ notification.FirstName }} 
                          {{ notification.LastName }} {{ notification.Message }}.
                          <br />
                          <span
                            v-if="notification?.order && notification?.order?.order_number"
                          >
                            Order Number: <strong>{{ notification.order.order_number }}</strong>
                          </span>
                          <p>
                            Date:
                            {{ notification.createdAt | moment("MMM D, YYYY h:mm A") }}
                          </p>
                        </li>
                      </div>
                  </ul>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </li>
        

        <!-- <li
          class="nav-item"
          @click="openUserMenu"
          v-click-outside="userMenuOutside"
        >
          <a class="nav-link">
            <img src="../../assets/images/profile32.png" alt="" />
          </a>
        </li> -->
      </ul>
    </div>

    <!-- <div v-if="userMenu" class="menu-options">
      <ul>
        <a @click="logout" class="menu-link">Logout</a>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import SocketioService from "../../services/socketio.service";

export default {
  name: "Navbar",
  data() {
    return {
      userMenu: false,
      isAdmin: false,
      isVerifier: false,
      enterpriseAdmin: false,
      notificationCount: 0,
      showNotifications: false,
      notifications: [],
      orders: [],
      refundOrders: [],
      riskNotifications: [],
      suspiciousNotifications: [],
      suspiciousNotificationsRefund: [],
      vpnOrderNotifications: [],
      vpnOrders: [],
      showLoader: 0,

    };
  },
 
  created() {
    SocketioService.setupSocketConnection();

    SocketioService.socket.on("newOrder", (newOrder) => {
      console.log("New order received:", newOrder);
      this.notificationCount++;
      // this.orders.push(newOrder);
      SocketioService.socket.emit(
        "acknowledgeOrder",
        newOrder.id,
        (response) => {
          console.log("Acknowledgment received from server:", response);
        }
      );
    });

    SocketioService.socket.on("newRefund", (newRefund) => {
      console.log("New refund received:", newRefund);
      this.notificationCount++;
      // this.refundOrders.push(newRefund);
      SocketioService.socket.emit(
        "acknowledgeRefund",
        newRefund,
        (response) => {
          console.log(
            "Acknowledgment received from server for refund:",
            response
          );
        }
      );
    });

    SocketioService.socket.on(
      "mediumRiskNotification",
      (mediumRiskNotification) => {
        console.log("New risk notification received", mediumRiskNotification);
        this.notificationCount++;
        // this.riskNotifications.push(mediumRiskNotification);
        SocketioService.socket.emit("acknowledgeOrder", (response) => {
          console.log("Acknowledgment received from server:", response);
        });
      }
    );
    SocketioService.socket.on("vpnOrderMismatch", (newVpnOrder) => {
      console.log("VPN Order mismatch received:", newVpnOrder);
      // this.vpnOrderNotifications.push(newVpnOrder);
      this.notificationCount++;
      // this.vpnOrderMismatches.push(newVpnOrder); // Assuming you have an array to hold these mismatches
    });

  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),
    getNotificationData() {
      this.showLoader = 1;
      axios
        .get(process.env.VUE_APP_API_URL + "getNotifications")
        .then((response) => {
          response.data.data.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
          console.log(response);
          this.notifications = response.data.data;
          this.getUnseenNotificationCount();
          this.showLoader = 0;
          
        })
        .catch(() => {
          console.log("hello");
        });
    },
    // getSuspiciousNotificationData() {
    //   axios
    //     .get(process.env.VUE_APP_API_URL + "getSuspiciousNotifications")
    //     .then(response => {
    //       response.data.Suspicious_Notifications.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    //       console.log(response);
    //       this.suspiciousNotifications=response.data.Suspicious_Notifications
    //       this.suspiciousNotificationsRefund=response.data.Suspicious_Notifications;
    //       this.vpnOrders = response.data.vpn_orders.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));

    //       this.notificationCount = 0;
    //     })
    //     .catch(() => {
    //       console.log("hello")
    //     });
    // },
    openUserMenu() {
      this.userMenu = !this.userMenu;
    },
    logout: function() {
      this.sendLogoutRequest().then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "Login" });
        } else {
          console.log("error");
        }
      });
    },
    userMenuOutside() {
      this.userMenu = false;
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
      this.getNotificationData();
      // this.getSuspiciousNotificationData();
    },
    hideNotifications() {
      this.showNotifications = false;
    },
    getUnseenNotificationCount() {
      this.showLoader = 1;

    axios
      .get(process.env.VUE_APP_API_URL + "getUnseenNotificationCount")
      .then((response) => {
        console.log(response.data,"agagga")
        console.log(response,"agagga")
        console.log(response.data.data.count,"agagga")
        this.notificationCount = response.data.data.count;
        this.showLoader = 0;
      })
      .catch((error) => {
        console.error("Error fetching unseen notifications count:", error);
        this.showLoader = 0;

      });
  },
    fetchNotifications() {
      axios
        .get(process.env.VUE_APP_API_URL + "getNotifications")
        .then((response) => {
          response.data.data.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
          this.notifications = response.data.data;
        })
        .catch(() => {
        });
    },
  },
  mounted() {
    this.getUnseenNotificationCount();
    if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    } else if (localStorage.getItem("permissions") == 4) {
      this.isVerifier = true;
    } else if (localStorage.getItem("permissions") == 5) {
      this.enterpriseAdmin = true;
    } else {
      this.isAdmin = false;
      this.isVerifier = false;
    }
  },
};
</script>

<style>
.red-circle {
  color: white;
  display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
  position: relative; /* All 'absolute'ly positioned elements are relative to this one */
  padding: 2px 5px; /* Add some padding so it looks nice */
}
.button__badge {
  background-color: #fa3e3e;
  border-radius: 50%;
  color: white;
  border: 1px solid white;
  padding: 1px 4px;
  font-size: 10px;
  position: absolute;
  top: -4px;
  right: -1px;
}

.modal-content {
  max-height: 800px;
}
/* .modal-body {
  height: 700px;
} */
.notifications-list {
  list-style: none;
  padding: 5px;
  border: 1px solid #8b8b8b;
  border-radius: 5px;
  height: 74%;
  overflow: auto;
  height: 400px;
}
.over {
  overflow: scroll;
}
.Suspicious_notifications {
  list-style: none;
  padding: 5px;
  border: 1px solid red;
  border-radius: 5px;
  height: 46%;
  overflow: auto;
}
.Suspicious_notifications li {
  list-style: none;
  padding: 5px;
  margin: 5px 0 5px 0;
  border-radius: 5px;
}
.notifications-list li {
  margin-bottom: 10px;
  border-radius: 5px;
}
.danger1 {
  background-color: #ff000010;
}
.danger2 {
  background-color: #ff000021;
}
.danger3 {
  background-color: #ff000042;
}
.labels{
    height: 100%;
    padding: 5px;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid darkslategray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
}
.type1 {
    background: rgb(169, 250, 232);

  }
.type2 {
    background: #f08080;
  }
.type3 {
    background: #90ee90;
  }
.type4 {
    background: #87cefa;
  }
.type5 {
    background: #f0e68c;
  }
.type6 {
  background-color: #8381fd35;
  }
.type7 {
  background: #d3d3d387;
}

</style>
