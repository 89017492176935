<template>
  <div>
    <div id="mySidebar" class="sidebar d-flex flex-column justify-content-between">
      <ul class="menu-list">
        <li class="menu-item">
          <router-link to="/orders" class="menu-link"  :class="{'active': isActiveTab('/orders')}">
            <div class="item-icon">
              <img src="../../assets/images/shock-sjield/bag-light.png" alt="" />
            </div>
            <div class="item-name">Orders</div>
          </router-link>
        </li>
        <li class="menu-item">
          <router-link to="/customers" class="menu-link" :class="{'active': isActiveTab('/customers')}">
            <div class="item-icon">
              <img src="../../assets/images/icon/users.png" alt="" />
            </div>
            <div class="item-name">Customers</div>
          </router-link>
        </li>
        <li class="menu-item">
          <router-link to="/RefundOrders" class="menu-link" :class="{'active': isActiveTab('/RefundOrders')}">
            <div class="item-icon">
              <img src="../../assets/images/icon/refund.png" alt="" />
            </div>
            <div class="item-name">Refund Orders</div>
          </router-link>
        </li>
        <li class="menu-item" v-if="User" >
          <router-link to="/Subscriptions" class="menu-link" :class="{'active': isActiveTab('/Subscriptions')}">
            <div class="item-icon">
              <img src="../../assets/images/icon/subscription.png" alt="" />
            </div>
            <div class="item-name">Subscriptions</div>
          </router-link>
        </li>
        <li class="menu-item" v-if="isAdmin" >
          <router-link to="/subscriptions" class="menu-link" :class="{'active': isActiveTab('/admin-subscriptions')}">
            <div class="item-icon">
              <img src="../../assets/images/icon/subscription.png" alt="" />
            </div>
            <div class="item-name">Admin Subscriptions</div>
          </router-link>
        </li>
      </ul>
      <ul class="menu-list">
        <li class="menu-item">
          <a @click="logout()" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/shock-sjield/Logout.png" alt="" />
            </div>
            <div class="item-name">Logout</div>
          </a>
        </li>

        
      </ul>
      <div class="closebtn" @click="closeNav()">
        <img src="../../assets/images/Collapse.png" alt="" />
      </div>
      <div id="main" @click="openNav()">
        <img src="../../assets/images/Collapse.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      User: false,
      isAdmin: false,
    };
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),

    openNav() {
      var x = window.matchMedia("(min-width: 769px)");
      if (x.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:180px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
      }
      var y = window.matchMedia("(max-width: 768px)");
      if (y.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:50px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
        document.querySelector(".registry-wrapper").setAttribute("style", "width : calc(100vw - 50px)");
        document.querySelector(".home-wrapper").setAttribute("style", "width : calc(100vw - 50px)");
      }
    },

    closeNav() {
      document.getElementById("mySidebar").setAttribute("style", "width:0");
      document.getElementById("main").setAttribute("style", "display:block");
      document.querySelector(".closebtn").setAttribute("style", "display:none");
    },

    logout() {
      this.sendLogoutRequest().then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "Login" });
        }
      });
    },

    // Updated method to check if the base path is in the current route path
    isActiveTab(basePath) {
      // Handle the root URL separately
      if (basePath === '/' && this.$route.path === '/') {
        return true;
      }
      // For all other paths
      return this.$route.path.startsWith(basePath);
    }
  },

  mounted() {
    if (localStorage.getItem("permissions") == 2) {
      this.User = true;
    } else if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    }
  },
};
</script>

<style scoped>
.active , .active .item-name {
    font-weight: 700;
    color: white!important;
    background-color: var(--light-zinc);
    border-radius: 5px;
    padding: 8px 4px!important;
    font-weight: 700!important;

}

.menu-item {
  margin-bottom: 10px;
}

/* Close button styles */
.closebtn {
  cursor: pointer;
}

/* Media query for sidebar responsiveness */
@media (max-width: 768px) {
  .sidebar {
    width: 50px;
  }
}
</style>
